import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { GlobalHeader } from '@sdtech/react-header-and-footer';
import GlobalFooter from '@sdtech/sd-ui/dist/commonjs/components/GlobalFooter/GlobalFooter';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import HomepageCarousel from './components/carousel/HomepageCarousel';
import CategoryContainer from './components/categoryContainer/CategoryContainer';
import SearchBar from './components/searchbar/SearchBar';
import config from './constants/config';

const { rhfConfig, isSignedIn, assetsBaseUrl, categoryDataArray, oneTrustCookie } = config;

const requestPath = '/';

/*
  The server has already rendered the entire page as a string and sent it to the client
  as the HTML template (see react.js) by the time the client gets to this file,
  the entry point for client.js.
  We do not need to hydrate all of the homepage, as most of it is static, so this
  file individually hydrates each of the components that need JS to work as expected.
*/

interface ComponentToHydrate {
  reactComponent: ReactElement;
  id: string;
}

[
  {
    reactComponent: (
      <GlobalHeader
        config={rhfConfig}
        searchEnabled={false}
        requestPath={requestPath}
        origin="home"
      />
    ),
    id: 'homepage-header',
  },
  {
    reactComponent: <SearchBar />,
    id: 'searchbar',
  },
  {
    reactComponent: (
        <HomepageCarousel assetsBaseUrl={assetsBaseUrl} />
    ),
    id: 'bannerCarousel',
  },
  {
    reactComponent: (
      <CategoryContainer
        isSignedIn={isSignedIn}
        categoryDataArray={categoryDataArray}
      />
    ),
    id: 'category-container',
  },
  {
    reactComponent: (
      <GlobalFooter
        config={rhfConfig}
        cookieBanner={oneTrustCookie.enabled}
        requestPath={requestPath}
      />
    ),
    id: 'homepage-footer',
  },
].forEach((component: ComponentToHydrate) => {
  const { reactComponent, id } = component;
  ReactDOM.hydrate(reactComponent, document.getElementById(id));
});
